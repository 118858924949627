$primary: #f07e25;
$secondary: #354b54;

@import "bootstrap/scss/bootstrap";

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-hover-color: color-contrast(shade-color($primary, $btn-hover-border-shade-amount));
  --bs-btn-active-color: color-contrast(shade-color($primary, $btn-active-bg-shade-amount));
  --bs-btn-disabled-color: color-contrast($primary);
}

body,
table {
  font-size: 0.9rem;
}

a,
.btn-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.btn:hover {
    text-decoration: none;
  }
}

.form-group {
  margin-bottom: 1rem;

  > label {
    font-weight: 500;
  }
}

.form-control-plaintext {
  font-weight: bold;
}

app-container > .container {
  .titlebar {
    margin-bottom: 30px;
    display: flex;

    h3 {
      flex: 1;
    }

    .buttons {
      button {
        margin-left: 10px;
      }
    }

    & ~ fa-icon[icon="spinner"] {
      display: block;
      font-size: 46px;
      margin: 40px 0;
      text-align: center;
    }
  }
}
